
import {
   ALL_FAQS , ALL_TERMS
} from "../constant/ActionType";

export default function DomainParameterReducer(state = {
    allFaqs: {},
   
    allterms:{}
    
}, action) {
    switch (action.type) {
        case ALL_FAQS:
          
        
        return {
                ...state,
                allFaqs: action?.data?.data,
     
            };


        case ALL_TERMS:
            return {
                ...state,
                allterms: action?.data?.data,
            };

        default:
    }

    return state;
}