// material-ui

import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { dispatch } from "store/index";
import {
  BatchProductSerialNo,
  FetchAllActiveCompanies,
  FetchAllActiveProducts,
  PostBatch,
  PostUpdateBatch,
} from "store/action/index";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CUBatch = ({ allProducts, allCompanies, available_serial_no, msg }) => {
  let location = useLocation();
  const today = new Date().toISOString().split("T")[0];

  const [manufacturing_date, setManufacturing_date] = useState(
    location.state !== null ? location.state?.manufacturing_date : today
  );
  const [expiry_date, setExpiry_date] = useState(
    location.state !== null ? location.state?.expiry_date : today
  );
  const [batch_name, setBatch_name] = useState(
    location.state !== null ? location.state?.batch_name : ""
  );

  const [remarks, setremarks] = useState(
    location.state !== null ? location.state?.remarks : ""
  );

  const [range_start, setrange_start] = useState(
    location.state !== null ? Number(location.state?.serial_start_rng) : 0
  );

  const [range_end, setrange_end] = useState(
    location.state !== null ? Number(location.state?.serial_end_rng) : 0
  );

  const [products, setProducts] = useState(
    location.state !== null ? location.state?.product_id : ""
  );
  const [company_id, setCompany_id] = useState(
    location.state !== null ? location.state?.company_id : ""
  );

  const [loading, setLoading] = useState(false);

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    dispatch(FetchAllActiveProducts());
    dispatch(FetchAllActiveCompanies());
  }, []);

  useEffect(() => {
    if (company_id) {
      const filtered =
        allProducts &&
        allProducts.length !== 0 &&
        allProducts.filter((item) => item.company_id === company_id);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(allProducts);
    }
  }, [company_id,allProducts]);

  const handleSubmit = () => {
    let formattedRangeStart = range_start;
    let formattedRangeEnd = range_end;

    if (range_start.length < 7) {
      const numberOfZerosToAdd = 7 - range_start.length;
      formattedRangeStart = "0".repeat(numberOfZerosToAdd) + range_start;
    }

    if (range_end.length < 7) {
      const numberOfZerosToAdd = 7 - range_end.length;
      formattedRangeEnd = "0".repeat(numberOfZerosToAdd) + range_end;
    }

    if (
      manufacturing_date !== "" &&
      expiry_date !== "" &&
      batch_name !== "" &&
      range_start !== "" &&
      range_end !== "" &&
      // serial_qty !== 0 &&
      products !== "" &&
      company_id !== ""
    ) {
      setLoading(true);

      if (location.state !== null) {
        dispatch(
          PostUpdateBatch({
            id: location.state.id,
            manufacturing_date: new Date(manufacturing_date).toLocaleDateString(
              "en-CA"
            ),
            expiry_date: new Date(expiry_date).toLocaleDateString("en-CA"),
            serial_start_rng: range_start,
            serial_end_rng: range_end,
            // serial_qty:serial_qty,
            remarks: remarks,
            product_id: products,
            batch_name: batch_name,
            company_id: company_id,
            setLoading: setLoading,
          })
        );
      } else {
        dispatch(
          PostBatch({
            manufacturing_date: new Date(manufacturing_date).toLocaleDateString(
              "en-CA"
            ),
            expiry_date: new Date(expiry_date).toLocaleDateString("en-CA"),
            serial_start_rng: formattedRangeStart,
            serial_end_rng: formattedRangeEnd,
            product_id: products,
            remarks: remarks,
            batch_name: batch_name,
            company_id: company_id,
            setLoading: setLoading,
          })
        );
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  const handleMinChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\D/g, "").slice(0, 10);
    setrange_start(formattedValue);
  };

  const handlemaxChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\D/g, "").slice(0, 10);

    setrange_end(formattedValue);
  };

  console.log("ssssmmsms", allCompanies);

  useEffect(() => {
    const validCompanyName = allCompanies?.filter(company => company?.company_id).map(company => company?.company_id);
    if (validCompanyName?.length === 1) {
      setCompany_id(validCompanyName[0]); 
    }
  }, [allCompanies]);

  useEffect(() => {
    if (Array.isArray(filteredProducts)) {
      const validProduct = filteredProducts
        .filter((product) => product.id)
        .map((product) => product.id);
      if (validProduct.length === 1) {
        setProducts(validProduct[0]);
      }
    } else {
      console.warn("filteredProducts is not an array:", filteredProducts);
    }
  }, [filteredProducts]);
  

  return (
    <MainCard>
      <Spin spinning={allCompanies && allCompanies.length !== 0 ? false : true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="row">
              <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">Company*</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={company_id}
                        onChange={(e) => setCompany_id(e.target.value)}
                        id="company"
                        name="roleid"
                        displayEmpty
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Company</em>
                        </MenuItem>

                        {allCompanies &&
                          allCompanies.length !== 0 &&
                          allCompanies.map((e, i) => {
                            return (
                              <MenuItem key={i} value={e.id}>
                                {e.company_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div>
              <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="Brand">Products*</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        disabled={company_id == ""}
                        value={products}
                        onChange={(e) => setProducts(e.target.value)}
                        id="products"
                        name="roleid"
                        displayEmpty
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Products</em>
                        </MenuItem>

                        {filteredProducts &&
                          filteredProducts.length !== 0 &&
                          filteredProducts.map((e, i) => {
                            return (
                              <MenuItem key={i} value={e.id}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div>
              <div className="col-md-4 my-2">
                <ThemeInput
                  id="batchName"
                  value={batch_name}
                  onChange={(e) => setBatch_name(e.target.value)}
                  type="text"
                  name="Serial Batch/Lot Number*"
                  placeholder="Enter Batch Name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-2">
                <ThemeInput
                  id="segment"
                  value={manufacturing_date}
                  onChange={(e) => setManufacturing_date(e.target.value)}
                  type="date"
                  name="Date 1*"
                  placeholder="Enter Manufacturing Date"
                />
              </div>
              <div className="col-md-4 my-2">
                <ThemeInput
                  id="range"
                  value={expiry_date}
                  onChange={(e) => setExpiry_date(e.target.value)}
                  type="date"
                  name="Date 2*"
                  placeholder="Enter Expiry Date"
                />
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  id="remarks"
                  value={remarks}
                  onChange={(e) => setremarks(e.target.value)}
                  type="text"
                  name="Remarks"
                  placeholder="Enter Remarks"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-2">
                <ThemeInput
                  id="digits"
                  value={range_start}
                  onChange={handleMinChange}
                  type="number"
                  name="Serial Start Range*"
                  placeholder="Enter Serial Start Range"
                />
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  id="digits"
                  value={range_end}
                  onChange={handlemaxChange}
                  type="number"
                  name="Serial End Range*"
                  placeholder="Enter Serial End Range"
                />
              </div>
              <div className="col-md-4 my-2">
                <Button
                  style={{ width: "auto" }}
                  className="px-5 my-4"
                  onClick={() => {
                    if (products !== "" && range_end !== 0) {
                      dispatch(
                        BatchProductSerialNo({
                          products: products,
                          serial_start_rng: range_start,
                          serial_end_rng: range_end,
                          setLoading: setLoading,
                        })
                      );
                    }
                  }}
                  disableElevation
                  size="large"
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Check Serial
                </Button>
              </div>
            </div>
            {msg !== null && (
              <p className="my-1" style={{ color: "green", fontWeight: 600 }}>
                {msg}
              </p>
            )}
            <div
              className="row my-5"
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 5,
              }}
            >
              <Button
                style={{ width: "auto" }}
                className="px-3"
                onClick={handleSubmit}
                disableElevation
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {location.state !== null ? "Edit Serial Batch/Lot" : "Add Serial Batch/Lot"}
              </Button>
            </div>{" "}
          </Grid>
        </Grid>
      </Spin>
    </MainCard>

    //  </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    allCompanies: state?.AllActiveCompanies?.AllActiveCompanies,
    allProducts: state?.AllActiveProducts?.ViewActiveProduct,
    available_serial_no: state?.AllBatchesReducers?.available_serial_no,
    msg: state?.AllBatchesReducers?.msg,
  };
};

export default connect(mapStateToProps)(CUBatch);
