
import {
 GET_ALL_ACTIVE_COMPANIES
} from "../constant/ActionType";

export default function AllActiveCompanies(state = {
    AllActiveCompanies: [],

}, action) {
    switch (action.type) {
        case GET_ALL_ACTIVE_COMPANIES:
            return {
                ...state,
                AllActiveCompanies: action?.data?.data,
            };

        default:
    }

    return state;
}