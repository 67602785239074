import {
    All_RANDOM_CODE
} from "../constant/ActionType";

export default function AllRandomCode(state = {
    ViewRandomCode: [],
    lastPage: 1,
}, action) {
    switch (action.type) {
        case All_RANDOM_CODE:
            return {
                ...state,
                ViewRandomCode: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}