
import {
    EXPORT_CSV_RANDOM_CODE
} from "../constant/ActionType";

export default function AllCsvRadmon(state = {
    csvRadom: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case EXPORT_CSV_RANDOM_CODE:
            return {
                ...state,
                csvRadom: action?.data,
                    statusCode: action?.status
            };

        default:
    }

    return state;
}