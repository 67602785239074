import { EXPORT_QR_CSV } from "../constant/ActionType";

export default function CSvQRReducer(
  state = {
    csvQRCode: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case EXPORT_QR_CSV:
      return {
        ...state,
        csvQRCode: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
