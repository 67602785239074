// type
import { Setting, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
  

// icons
const icons = {
  navigation: Setting,
  typography: Setting,
  color: Colorfilter,
  shadow: Barcode
};

let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some(permission => permission?.includes(brand));
}

// ==============================|| MENU ITEMS - sms ||============================== //

const sms = permissionsAccessFunction('sms') ? {

  id: 'utilities',
  title: 'SMS Management',
  icon: icons.navigation,
  type: 'group',
  children: [
   
    allowedPermissions?.includes('update-ratelimit') ? {
    
      id: 'update-rate-limit',
      title: 'Update Rate Limit',
      type: 'item',
      url: '/random/update-rate-limit',
      icon: icons.navigation,
      breadcrumbs: true
    } : null,

    allowedPermissions?.includes('create-Replay-sms') || allowedPermissions.includes('edit-smsReplay')
    ? {
      id: 'SMS Reply',
      title: 'SMS Reply',
      type: 'item',
      url: '/sms/get-sms-reply',
      icon: icons.typography,
      breadcrumbs: true
    } : null,
  
  ].filter(Boolean)
}: null;


export default sms;
