import {
    MAP_DATA
} from "../constant/ActionType";

export default function MapDataReducers(state = {
    mapalldata: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case MAP_DATA:
            return {
                ...state,
                mapalldata: action.data,
                    statusCode: action.status
            };

        default:
    }

    return state;
}