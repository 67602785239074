// type
import { Key, Text, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";

// icons
const icons = {
  navigation: Key,
  typography: Text,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissionsArray = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  localStorage.clear();
  window.location.reload();
}
  }
  
  const permissionsArray = JSON.parse(decryptedData)?.permissions;

const allowedPermissions = permissionsArray && permissionsArray.length !== 0 ? permissionsArray?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions?.length !== 0 && allowedPermissions?.some(permission => permission?.includes(brand));
}
// ==============================|| MENU ITEMS - permissions ||============================== //

const permissions = permissionsAccessFunction('permission') ? {
  id: 'utilities',
  title: 'Permissions',
  icon: icons.navigation,
  type: 'group',
  children: [
    allowedPermissions?.includes('assign-permission') ? {
      id: 'assign',
      title: 'Assign Permissions',
      type: 'item',
      url: '/permissions/assign',
      icon: icons.navigation,
      breadcrumbs: true
    } : null
  ].filter(Boolean)
} : null;

export default permissions;
