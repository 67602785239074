import {
    ALL_SMS_REPORT, ALL_QRCODE_REPORT
} from "../constant/ActionType";

export default function Reports(state = {
    ViewSmsReport: [],
    ViewQrcodeReport: [],
    lastPageSMS: 1,
    lastPageQR: 1,
}, action) {
    switch (action.type) {
        case ALL_SMS_REPORT:
            return {
                ...state,
                ViewSmsReport: action?.data?.data,
                lastPageSMS: action?.data?.data?.logs?.last_page,
            
            };
        case ALL_QRCODE_REPORT:
            return {
                ...state,
                ViewQrcodeReport: action?.data?.data,
                lastPageQR: action?.data?.data?.logs?.last_page,
        
            };

        default:
    }

    return state;
}