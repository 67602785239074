import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GetFaqs, PostFAQSs } from 'store/action/index';
import { dispatch } from 'store/index';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Spin } from 'antd';
import MainCard from 'components/MainCard';
import { Button } from "@mui/material"
import AnimateButton from 'components/@extended/AnimateButton';

// Function to strip HTML tags and convert to text
function stripHtmlTags(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const FAQs = ({ allFaqs }) => {
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Load FAQs from the server
  useEffect(() => {
    dispatch(GetFaqs({
      setLoading: setLoading
    }));
  }, []);

  // Set the Editor content when FAQs are available
  useEffect(() => {
    if (allFaqs && allFaqs.content) {
      const textContent = stripHtmlTags(allFaqs.content); // Strip HTML tags and get plain text
      const contentState = ContentState.createFromText(textContent);
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [allFaqs]);

  // Handle posting data from Editor to dispatch
  const handlePostData = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const htmlString = html.toString();
    dispatch(PostFAQSs({
      content: htmlString,
      setLoading: setLoading
    }));


  };

  return (
    <>
      <Spin spinning={loading}>
        <MainCard>
          <div style={{ height: '300px', overflowY:'auto' }}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={setEditorState}
            />
          </div>
        </MainCard>

        <div className='row'>
          <div className='col-md-10'></div>
          <div className='col-md-2 my-3'>
            <AnimateButton>
              <Button
                onClick={handlePostData}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </AnimateButton>
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allFaqs: state?.DomainParameter?.allFaqs,
  };
};

export default connect(mapStateToProps)(FAQs);
