import {
    GET_ALL_COLOR_SHIFT
} from "../constant/ActionType";

export default function allColorShift(state = {
    ViewColor: [],
    lastPage: 1,
}, action) {
    switch (action.type) {
        case GET_ALL_COLOR_SHIFT:
            return {
                ...state,
                ViewColor: action?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}