// type
import { Category, Category2, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Category,
  typography: Category2,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;
let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;
const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some(permission => permission?.includes(brand));
}

// ==============================|| MENU ITEMS - roles ||============================== //

const subCategories = permissionsAccessFunction('subcategory') ? {
  id: 'utilities',
  title: 'Sub Category',
  icon: icons.navigation,
  type: 'group',
  children: [
 
   
    allowedPermissions?.includes('get-subcategory') ?  {
      id: 'get-subcategory',
      title: 'Get Sub Category',
      type: 'item',
      url: '/subcategory/get-subcategory',
      icon: icons.typography,
      breadcrumbs: true
    } : null,
    allowedPermissions?.includes('register-subcategory') ? {
      id: 'edit-subcategory',
      title: 'Register Sub Category',
      type: 'item',
      url: '/subcategory/edit-subcategory',
      icon: icons.typography,
      breadcrumbs: true
    } : null,
    
  ].filter(Boolean)
} : null;

export default subCategories;
