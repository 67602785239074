
import {
    ALL_BRAND
} from "../constant/ActionType";

export default function ViewallBrandssRedUsers(state = {
    viewallbrand: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_BRAND:
            return {
                ...state,
                viewallbrand: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}