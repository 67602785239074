// type
import { ShopRemove } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";

// icons
const icons = {
    navigation: ShopRemove,
    typography: ShopRemove,
    color: ShopRemove,
    shadow: ShopRemove
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some(permission => permission?.includes(brand));
}
// ==============================|| MENU ITEMS - roles ||============================== //

const companies = permissionsAccessFunction('company') ? {
    id: 'utilities',
    title: 'Company',
    icon: icons.navigation,
    type: 'group',
    children: [

        allowedPermissions?.includes('get-company') ? {
            id: 'all-company',
            title: 'Company',
            type: 'item',
            url: '/company/all-company',
            icon: icons.typography,
            breadcrumbs: true
        } : null,
        allowedPermissions?.includes('register-company') ? {
            id: 'create-company',
            title: 'Add Company',
            type: 'item',
            url: '/company/create-company',
            icon: icons.typography,
            breadcrumbs: true
        } : null,

    ].filter(Boolean)
} : null;

export default companies;
