// material-ui

import MainCard from "components/MainCard";

import { onPageChange } from "helper/paginationHelper";
import GetColumnSearchProps from "helper/searchHelper";
import Chip from "@mui/material/Chip";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import EcommerceDataChart from "sections/dashboard/EcommerceDataChart";
import { format } from "date-fns";
import { Category2 } from "iconsax-react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ThemeInput from "components/inputs/ThemeInput";
import { CSVLink } from "react-csv";
import {
  AllBatchesByCompany,
  FetchAllActiveCompanies,
  GetAllSMSLogs,
  FetchAllActiveProducts,
  AllBatches,
  RegisterProductz,
  BatchesGetAll,
} from "store/action/index";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import AnimateButton from "components/@extended/AnimateButton";

import {
  Table,
  //  Popconfirm,
  Pagination,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  FetchExportCSV,
  GetAllSMSReport,
  AllCompanies,
  FetchAllProductz,
  // SubCategoryUpdateStatus
} from "store/action/index";
import { dispatch } from "store/index";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AllSMSReports = ({
  SmsLogs,
  lastPage,
  exportcsv,
  allcompany,
  viewAllProducts,
  allbatches,
  viewallBatchgetAll,
}) => {
  // let permissionUser = localStorage.getItem('LoginData');
  // let permissions = JSON.parse(permissionUser)?.permissions;

  // const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];
  // const navigate = useNavigate()
  const theme = useTheme();
  const [totalPageView, setTotalPageView] = useState(10);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [searchBatch, setsearchBatch] = useState("");
  const [searchCompany, setsearchCompany] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchProduct, setsearchProduct] = useState("");
  const [filteredBatch, setFilteredBatch] = useState([]);
  const [uopdate, setUpdate] = useState("");
  const [from_date, setDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [params, setparams] = useState({
    page: 1,
    from_date: from_date,
    to_date: to_date,
    company_id: searchCompany,
    product_id: searchProduct,
    batch_id: searchBatch,
  });
  const [paramms, setparamms] = useState({
    page: 1,
  });
  const [ExportcsvData, setExportCsv] = useState([]);

  const onShowSizeChange = (current, pageSize) => {
    setTotalPageView(pageSize);
  };
  const EXportCsv = () => {
    dispatch(FetchExportCSV(params));
  };
  const tableStyle = {
    flex: 1,
    overflowX: "auto",
    marginTop: 5,
  };

  useEffect(() => {
    setparams({
      ...params,
      from_date,
      to_date,
      to_date: to_date,
      company_id: searchCompany,
      product_id: searchProduct,
      batch_id: searchBatch,
    });
  }, [uopdate]);

  const handleSubmit = () => {
    dispatch(
      GetAllSMSReport({
        params: {
          ...params,
          company_id: +searchCompany,
          product_id: searchProduct,
          batch_id: searchBatch,
          to_date: to_date,
          from_date: from_date,
        },
        setLoading: setLoading,
      })
    );
    dispatch(
      FetchExportCSV({
        params: {
          ...params,
          company_id: +searchCompany,
          product_id: searchProduct,
          batch_id: searchBatch,
          to_date: to_date,
          from_date: from_date,
        },
        setLoading: setLoading,
      })
    );
  };

  useEffect(() => {
    // dispatch(FetchAllActiveCompanies());
    dispatch(AllCompanies());
    dispatch(BatchesGetAll());
    dispatch(FetchAllProductz({ paramms: paramms, setLoading: setLoading }));
    dispatch(AllBatches({ paramms: paramms, setLoading: setLoading }));

    // dispatch(FetchAllActiveProducts());
    // const param = { ...params, limit: totalPageView ?? 10 };
    dispatch(GetAllSMSLogs({ params: params, setLoading: setLoading }));
    // dispatch(
    //   AllBatchesByCompany({
    //     params: { ...params, status: 1, paginate: "all" },
    //     setLoading: setLoading,
    //   }),
    // );
  }, []);

  useEffect(() => {
    console.log("viesfdfsll", viewAllProducts);
  }, [viewAllProducts]);

  useEffect(() => {
    if (searchCompany) {
      const filtered =
        viewAllProducts &&
        viewAllProducts.length !== 0 &&
        viewAllProducts.filter((item) => item.company_id == searchCompany);
      setFilteredProducts(filtered);
      const filterBatch =
        Array.isArray(viewallBatchgetAll) &&
        viewallBatchgetAll?.length !== 0 &&
        viewallBatchgetAll?.filter((item) => item.company_id == searchCompany);
      console.log("AllBatchesFilters", filterBatch);

      setFilteredBatch(filterBatch);
    } else {
      setFilteredProducts(viewAllProducts);
      if (Array.isArray(viewallBatchgetAll)) {
        setFilteredBatch(viewallBatchgetAll);
      }
    }
  }, [searchCompany, viewallBatchgetAll]);

  useEffect(() => {
    if (exportcsv?.logs) {
      const newArray = exportcsv?.logs?.map(
        ({
          id,
          batch_id,
          product_id,
          company_id,
          brand_id,
          status,
          created_at,
          updated_at,
          ...rest
        }) => rest
      );
      setExportCsv(newArray);
    }
  }, [exportcsv?.logs]);

  useEffect(() => {
    EXportCsv();
    const param = { ...params, limit: totalPageView ?? 10 };
    // dispatch(GetAllSMSReport({ params: param, setLoading: setLoading }));
    setLoading(true);

    const timeoutId = setTimeout(() => {
      dispatch(GetAllSMSReport({ params: param, setLoading: setLoading }));
      dispatch(FetchExportCSV({ params: param, setLoading: setLoading }));
    }, 2000);

    // Clear the timeout if the component unmounts or if params change again
    return () => {
      clearTimeout(timeoutId);
    };
  }, [params]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!exportcsv?.logs) {
        setButtonDisabled(true);
      }
    }, 10000); // 10 seconds in milliseconds

    return () => clearTimeout(timeout);
  }, [exportcsv?.logs]);

  const columns = [
    {
      title: "From Number",
      dataIndex: "number",
      key: "number",
      ...GetColumnSearchProps("number", params, setparams),
    },

    {
      title: "Recieved Text",
      dataIndex: "text",
      key: "text",
      ...GetColumnSearchProps("text", params, setparams),
    },
    {
      title: "Text Response",
      dataIndex: "response",
      key: "response",
    },
    {
      title: "Company ",
      dataIndex: "company_name",
      key: "company_name",
    },

    {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Batch",
      dataIndex: "batch_name",
      key: "batch_name",
    },

    {
      title: " Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <span>
            {status === 1 ? (
              // <Chip label="V" color="success" style={{ borderRadius: 8 }} />
              <p style={{ color: "green" }}>V</p>
            ) : status === 2 ? (
              // <Chip label="D" color="secondary" style={{ borderRadius: 8 }} />
              <p style={{ color: "blue" }}>D</p>
            ) : status === 5 ? (
              // <Chip label="F" color="error" style={{ borderRadius: 8 }} />
              <p style={{ color: "red" }}>F</p>
            ) : status === 3 ? (
              // <Chip label="B/D" color="error" style={{ borderRadius: 8 }} />
              <p style={{ color: "red" }}>B/D</p>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
  ];

  const ResetFilter = () => {
    setLoading(true);
    setsearchCompany("");
    setsearchProduct("");
    setsearchBatch("");
    setDate("");
    setToDate("");

    dispatch(GetAllSMSReport({ setLoading: setLoading }));
    dispatch(FetchExportCSV({ setLoading: setLoading }));
  };

  const HandleChange = (page) => {
    onPageChange(setparams, params, page);
    setUpdate(page);
    console.log("check state", searchCompany);
  };

  useEffect(() => {
    console.log("companydata", allcompany);
  }, [allcompany]);

  useEffect(() => {
    const validCompanyIds = allcompany
      ?.filter((company) => company.company_id)
      .map((company) => company.company_id);
    if (validCompanyIds?.length === 1) {
      setsearchCompany(validCompanyIds[0]);
    }
  }, [allcompany]);

  useEffect(() => {
    console.log("Checking Length", filteredProducts); // Log the filtered products array

    if (Array.isArray(filteredProducts) && filteredProducts.length == 1) {
      setsearchProduct(filteredProducts[0].id); // Auto-select the first product if only one
    }
  }, [filteredProducts]);

  const getBatchOptions = () => {
    const batchData = searchCompany !== "" ? viewallBatchgetAll : filteredBatch;

    if (!Array.isArray(batchData) || batchData.length === 0) {
      return [];
    }

    return batchData.map((batch, index) => ({
      id: batch.id,
      displayText: batch.batch_name || batch.sale_no,
    }));
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "40px",
        }}
      >
        <Grid item xs={12} sm={6} lg={2}>
          <EcommerceDataCard
            title="Total Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {SmsLogs?.totalCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <EcommerceDataCard
            title="Correct Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {SmsLogs?.CorrectCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <EcommerceDataCard
            title="Fake Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {SmsLogs?.WrongCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Blocked Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {SmsLogs?.BlockedCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <EcommerceDataCard
            title="Duplicate Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {SmsLogs?.DuplicateCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
      </div>

      <div className="container-fluid">
        <div
          className="row"
          style={{ width: "100%", marginLeft: "12px", marginTop: "40px" }}
        >
          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="company">Search By Company</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={searchCompany}
                  onChange={(e) => setsearchCompany(e.target.value)}
                  id="company"
                  name="roleid"
                  displayEmpty
                  input={<OutlinedInput />}
                >
                  <MenuItem disabled value="">
                    <em>Search Company</em>
                  </MenuItem>
                  <MenuItem value="">None</MenuItem>

                  {allcompany &&
                    allcompany.length !== 0 &&
                    allcompany.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.id}>
                          {e?.company_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </div>

          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="company">Search By Product*</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={searchProduct}
                  onChange={(e) => setsearchProduct(e.target.value)}
                  id="company"
                  name="roleid"
                  displayEmpty
                  input={<OutlinedInput />}
                >
                  <MenuItem disabled value="">
                    <em>Search Product</em>
                  </MenuItem>
                  <MenuItem value="">None</MenuItem>

                  {Array.isArray(filteredProducts) &&
                  filteredProducts.length === 1 ? (
                    // Auto-select behavior when there's only one product
                    <MenuItem value={filteredProducts[0].id}>
                      {filteredProducts[0].name}
                    </MenuItem>
                  ) : (
                    // Show all products when there are multiple
                    Array.isArray(filteredProducts) &&
                    filteredProducts.length > 0 &&
                    filteredProducts.map((e, i) => (
                      <MenuItem key={i} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}

                  {Array.isArray(filteredProducts) &&
                    filteredProducts.length === 0 && (
                      // Option when no products are available
                      <MenuItem disabled>
                        <em>No products available</em>
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </Stack>
          </div>

          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="batch-search">
                Search By Batch<span className="text-red-500">*</span>
              </InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="batch-search"
                  name="batch-search"
                  value={searchBatch}
                  onChange={(e) => setsearchBatch(e.target.value)}
                  input={<OutlinedInput />}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Search Batch</em>
                  </MenuItem>

                  <MenuItem value="">None</MenuItem>

                  {getBatchOptions().map(({ id, displayText }) => (
                    <MenuItem key={id} value={id}>
                      {displayText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </div>

          <div className="col-md-4">
            <ThemeInput
              onChange={(e) => setDate(e.target.value)}
              type="date"
              name="Form Date"
              value={from_date}
            />
          </div>
          <div className="col-md-4">
            <ThemeInput
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              name="To Date"
              value={to_date}
            />
          </div>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={10}>
            <div className="row width100percent">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                ></div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <AnimateButton>
                      <Button
                        onClick={ResetFilter}
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Reset
                      </Button>
                    </AnimateButton>
                  </div>

                  <AnimateButton>
                    <Button
                      onClick={handleSubmit}
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Search
                    </Button>
                  </AnimateButton>
                  <CSVLink
                    filename={"ExportSMSReport.csv"}
                    data={ExportcsvData ?? []}
                    className="css-h4we56-MuiButtonBase-root-MuiButton-root"
                    style={{
                      background: "#58D62A",
                      marginLeft: "30px",
                      width: "134px",
                      fontSize: "13px",
                    }}
                    disabled={isButtonDisabled}
                  >
                    {exportcsv?.logs ? " Export to CSV" : "Loading.."}
                  </CSVLink>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div className="row" style={{ width: "100%" }}>
        <MainCard sx={tableStyle}>
          <Spin spinning={loading}>
            <p sx={{ color: "green", marginTop: "10px", fontSize: "30px" }}>
              <span style={{ fontSize: "27px" }}> v =</span>
              <Chip
                label="Verified"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 8,
                }}
              />
              , <span style={{ color: "red", fontSize: "22px" }}> F =</span>
              <Chip label="Fake" color="error" style={{ borderRadius: 8 }} />,
              <span style={{ color: "blue", fontSize: "22px" }}> D =</span>
              <Chip
                label="Duplicate"
                color="secondary"
                style={{ borderRadius: 8 }}
              />
              , <span style={{ color: "red", fontSize: "22px" }}>B/D =</span>
              <Chip
                label="Block/Duplicate"
                color="error"
                style={{ borderRadius: 8 }}
              />
            </p>
            <div style={{ overflowX: "auto", width: "100%" }}>
              <Table
                className="border rounded"
                style={{ marginTop: 25, overflowX: "auto", width: "100%" }}
                dataSource={SmsLogs?.logs?.data}
                columns={columns}
                pagination={false}
                rowKey="id"
                components={{
                  body: {
                    cell: ({ style, ...restProps }) => {
                      return (
                        <td
                          style={{
                            ...style,
                            padding: "1px",
                            textAlign: "center",
                          }}
                          {...restProps}
                        />
                      );
                    },
                  },
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 25,
              }}
            >
              <Pagination
                total={Number(lastPage ? lastPage : 1) * 10}
                defaultCurrent={params.paginate}
                defaultPageSize={totalPageView}
                onShowSizeChange={onShowSizeChange}
                onChange={(page) => HandleChange(page)}
                // onChange={(page) => onPageChange(setparams, params, page)}
              />
            </div>
          </Spin>
        </MainCard>
      </div>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    SmsLogs: state?.Reports?.ViewSmsReport,
    lastPage: state?.Reports?.lastPageSMS,
    exportcsv: state?.ExportReducerCsv?.exportcsv,
    allbatches: state?.AllBatchesReducers?.allbatches,
    allcompany: state?.AllCompaniesReducers?.allcompany,
    viewAllProducts: state?.AllProducts?.ViewProductz,
    viewallBatchgetAll: state?.AllBatchesBothGet?.viewallBatchgetAll,
  };
};

export default connect(mapStateToProps)(AllSMSReports);
