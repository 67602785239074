import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import RegisterProduct from "pages/products/registerProduct";
import CuBatch from "pages/batch/cuBatch";
import AllSmsReports from "pages/reports/allSmsReports";
import AllQRcodeReports from "pages/reports/allQrcodeReports";
import FAQs from "pages/DomainParameter/FAQs";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const SecurityCHnageee = Loadable(
  lazy(() => import("pages/changePassword.js"))
);
// render - data display components
const EditBrands = Loadable(lazy(() => import("pages/brands/editbrands")));
const AllBatch = Loadable(lazy(() => import("pages/batch/allBatch")));
const CustomBatch = Loadable(lazy(() => import("pages/batch/AllCustomBatch")));
const CreateCustomBatch = Loadable(lazy(() => import("pages/batch/CreateCustomBatch")));
const AllCustomer = Loadable(lazy(() => import("pages/customer/AllCustomer")));
const CreateCustomer = Loadable(lazy(() => import("pages/customer/CreateCustomer")));
const RegisterBrand = Loadable(
  lazy(() => import("pages/brands/registerBrand"))
);
const RegisterUser = Loadable(
  lazy(() => import("pages/user-management/registerUser"))
);
const AllProducts = Loadable(lazy(() => import("pages/products/allproducts")));
const AllRoles = Loadable(lazy(() => import("pages/user-management/allroles")));
const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));
const AllBranded = Loadable(lazy(() => import("pages/brands/getallbrand")));
const AllsCategaeorys = Loadable(
  lazy(() => import("pages/categeory/allcategeories"))
);
const EditCategory = Loadable(
  lazy(() => import("pages/categeory/editcategory"))
);
const SubCategoryALL = Loadable(
  lazy(() => import("pages/sub-category/allsubcategory"))
);
const SubEditCategory = Loadable(
  lazy(() => import("pages/sub-category/editsubcategory"))
);
const CompanyAll = Loadable(lazy(() => import("pages/company/allcompany")));
const CreateCompany = Loadable(
  lazy(() => import("pages/company/createeditcompany"))
);
const RandomCode = Loadable(
  lazy(() => import("pages/randomcode/allrandomcode"))
);
const ProgressivePage = Loadable(
  lazy(() => import("pages/randomcode/ProgressivePage"))
);
const RandomCodeEdit = Loadable(
  lazy(() => import("pages/randomcode/editrandomcode"))
);
const CreateUpdateSMS = Loadable(
  lazy(() => import("pages/sms/createUpdateSMS"))
);
const RecievedSMSLogs = Loadable(
  lazy(() => import("pages/sms/receivedSMSLogs"))
);
const UpdateRateLimit = Loadable(
  lazy(() => import("pages/randomcode/updateRateLimit"))
);
const UpdateColorShift = Loadable(
  lazy(() => import("pages/ColorShifting/updateColorShift"))
);
const GetColorShift = Loadable(
  lazy(() => import("pages/ColorShifting/getColorShift"))
);
const SmsReply = Loadable(lazy(() => import("pages/sms/smsReply")));
const CreateSmsReply = Loadable(
  lazy(() => import("pages/sms/create-sms-reply"))
);
const TermAndPolicy = Loadable(
  lazy(() => import("pages/DomainParameter/TermsAndPolicy"))
);
const ReportProblem = Loadable(
  lazy(() => import("pages/reports/ProblemReport"))
);
const QrMapsComponent = Loadable(
  lazy(() => import("pages/reports/qrreportmap"))
);

// render - utils components page
// const Color = Loadable(lazy(() => import('pages/components-overview/color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/shadows')));

// pages routing

// ==============================|| MAIN ROUTES ||============================== //
// let permissionUser = localStorage.getItem('LoginData');
// let permissions = JSON.parse(permissionUser)?.permissions;
let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location = "/";
    window.location.reload();
  }
}

const permissions = JSON.parse(decryptedData)?.permissions;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions?.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions?.some((permission) => permission?.includes(brand))
  );
};

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <DashboardDefault />,
        },
        {
          path: "dashboard",
          element: <DashboardDefault />,
        },
        {
          path: "*",
          element: <DashboardDefault />,
        },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <chnagePassword />,
        },
        {
          path: "change-Password",
          element: <SecurityCHnageee />,
        },
        {
          path: "*",
          element: <chnagePassword />,
        },
      ],
    },

    permissionsAccessFunction("company")
      ? {
          path: "company",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-company")
              ? {
                  path: "all-company",
                  element: <CompanyAll />,
                }
              : null,
            allowedPermissions?.includes("register-company") ||
            allowedPermissions?.includes("edit-company")
              ? {
                  path: "create-company",
                  element: <CreateCompany />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    permissionsAccessFunction("user")
      ? {
          path: "user-management",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-user")
              ? {
                  path: "users",
                  element: <AllUsers />,
                }
              : null,
            allowedPermissions?.includes("register-user") ||
            allowedPermissions?.includes("edit-user")
              ? {
                  path: "register-user",
                  element: <RegisterUser />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    // permissionsAccessFunction('brand')
    // ?
    {
      path: "sms",
      element: <MainLayout />,
      children: [
        // allowedPermissions.includes('register-brand')
        //   ?
        {
          path: "create-sms",
          element: <CreateUpdateSMS />,
        },
        {
          path: "recieved-sms-logs",
          element: <RecievedSMSLogs />,
        },
        allowedPermissions?.includes("get-Replay-sms")
          ? {
              path: "get-sms-reply",
              element: <SmsReply />,
            }
          : null,
        allowedPermissions?.includes("create-Replay-sms") ||
        allowedPermissions?.includes("edit-Replay-sms")
          ? {
              path: "create-sms-reply",
              element: <CreateSmsReply />,
            }
          : null,

        // : null,
      ].filter(Boolean),
    },

    permissionsAccessFunction("report")
      ? {
          path: "reports",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("sms-report")
              ? {
                  path: "all-sms-reports",
                  element: <AllSmsReports />,
                }
              : null,

            // {
            //   path: "scan-qr-location",
            //   element: <QrMapsComponent />,
            // },
            allowedPermissions?.includes("qr-report")
              ? {
                  path: "all-qrcode-reports",
                  element: <AllQRcodeReports />,
                }
              : null,
            allowedPermissions?.includes("problem-report")
              ? {
                  path: "reports-problem",
                  element: <ReportProblem />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    permissionsAccessFunction("product")
      ? {
          path: "products",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-product")
              ? {
                  path: "all-products",
                  element: <AllProducts />,
                }
              : null,
            allowedPermissions?.includes("register-product") ||
            allowedPermissions?.includes("edit-product")
              ? {
                  path: "create-product",
                  element: <RegisterProduct />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
   true
      ? {
          path: "roles",
          element: <MainLayout />,
          children: [
           true
              ? {
                  path: "all-roles",
                  element: <AllRoles />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    permissionsAccessFunction("categories")
      ? {
          path: "category",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-categories")
              ? {
                  path: "all-category",
                  element: <AllsCategaeorys />,
                }
              : null,
            allowedPermissions?.includes("register-categories") ||
            allowedPermissions?.includes("edit-categories")
              ? {
                  path: "edit-category",
                  element: <EditCategory />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    permissionsAccessFunction("subcategory")
      ? {
          path: "subcategory",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-subcategory")
              ? {
                  path: "get-subcategory",
                  element: <SubCategoryALL />,
                }
              : null,

            allowedPermissions?.includes("register-subcategory") ||
            allowedPermissions?.includes("edit-subcategory")
              ? {
                  path: "edit-subcategory",
                  element: <SubEditCategory />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

      permissionsAccessFunction("customer")
      ? {
          path: "customer",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("customer.get")
              ? {
                  path: "all-customer",
                  element: <AllCustomer />,
                }
              : null,

            allowedPermissions?.includes("customer.create") ||
            allowedPermissions?.includes("customer.update")
              ? {
                  path: "register-customer",
                  element: <CreateCustomer />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,


    permissionsAccessFunction("batch")
      ? {
          path: "batch",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-batch")
              ? {
                  path: "all-batch",
                  element: <AllBatch />,
                }
              : null,

            allowedPermissions?.includes("create-batch") ||
            allowedPermissions?.includes("update-batch")
              ? {
                  path: "register-batch",
                  element: <CuBatch />,
                }
              : null,
              allowedPermissions?.includes("custom.batch.get")
                ? {
                    path: "custom-batch",
                    element: <CustomBatch />,
                  }
                : null,
                allowedPermissions?.includes("custom.batch.create") ||
                allowedPermissions?.includes("custom.batch.update") 
                  ? {
                      path: "register-custom-batch",
                      element: <CreateCustomBatch/>,
                    }
                  : null,
          ].filter(Boolean),
        }
      : null,

    permissionsAccessFunction("permission")
      ? {
          path: "permissions",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("assign-permission")
              ? {
                  path: "assign",
                  element: <PermissionsAssign />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    permissionsAccessFunction("randomcodes") ||
    permissionsAccessFunction("update-ratelimit")
      ? {
          path: "random",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-randomcodes")
              ? {
                  path: "all-random-code",
                  element: <RandomCode />,
                }
              : null,
            allowedPermissions?.includes("create-randomcodes")
              ? {
                  path: "create-random-code",
                  element: <RandomCodeEdit />,
                }
              : null,
            allowedPermissions?.includes("update-ratelimit")
              ? {
                  path: "update-rate-limit",
                  element: <UpdateRateLimit />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    permissionsAccessFunction("colorShifting") ||
    permissionsAccessFunction("FAQs") ||
    permissionsAccessFunction("TermsAndPolicy")
      ? {
          path: "DomainParameter",
          element: <MainLayout />,
          children: [
            allowedPermissions?.includes("get-colorShifting")
              ? {
                  path: "get-colorShifting",
                  element: <GetColorShift />,
                }
              : null,

            allowedPermissions?.includes("update-colorShifting")
              ? {
                  path: "update-colorShifting",
                  element: <UpdateColorShift />,
                }
              : null,

            allowedPermissions?.includes("FAQs")
              ? {
                  path: "FAQs",
                  element: <FAQs />,
                }
              : null,

            allowedPermissions?.includes("TermsAndPolicy")
              ? {
                  path: "TermsAndPolicy",
                  element: <TermAndPolicy />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
  ].filter(Boolean),
};

export default MainRoutes;
