// type
import { Profile2User, Text, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Profile2User,
  typography: Text,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem('LoginData');
// let permissions = JSON.parse(permissionUser)?.permissions;
let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  localStorage.clear();
  window.location.reload();
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;
const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map((permission) => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some((permission) => permission?.includes(brand));
};

// ==============================|| MENU ITEMS - COMPONENTS ||============================== //

const components = permissionsAccessFunction('user')
  ? {
      id: 'utilities',
      title: 'User Management',
      icon: icons.navigation,
      type: 'group',
      children: [
        allowedPermissions?.includes('get-user')
          ? {
              id: 'users',
              title: 'All Users',
              type: 'item',
              url: '/user-management/users',
              icon: icons.navigation,
              breadcrumbs: true
            }
          : null,
        allowedPermissions?.includes('register-user')
          ? {
              id: 'register-users',
              title: 'Users',
              type: 'item',
              url: '/user-management/register-user',
              icon: icons.navigation,
              breadcrumbs: true
            }
          : null
      ].filter(Boolean)
    }
  : null;

export default components;
