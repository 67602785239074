import shop from "../shop/shop";
import * as types from "../constant/ActionType";

export const fssetchsogsinUser = (data) => ({
  type: types.FETCH_LOGIN_DATA,
  data,
});
export const fetchcilDloginVerrr = (data) => ({
  type: types.VERIFICATION_LOGINN,
  data,
});

export const ForgetPasswordz = (data) => ({
  type: types.FORGET_PASSWORD,
  data,
});

export const UpdateLimit = (data) => ({
  type: types.UPDATE_RATE_LIMIT,
  data,
});

export const brandoownerRegis = (data) => ({
  type: types.REGISTER_BRAND_OWNER,
  data,
});

export const branduserfetchregister = (data) => ({
  type: types.REGISTER_BRAND_USER,
  data,
});

export const updatestatuscodes = (data) => ({
  type: types.UPDATE_STATUS_CODE,
  data,
});

export const specificroless = (data) => ({
  type: types.CREATE_ROLE,
  data,
});

export const Childassigpermissions = (data) => ({
  type: types.ASSIGN_PERMISSIONS,
  data,
});

export const childALLPermisssions = (data) => ({
  type: types.GETALL_PERMISSION,
  data,
});
export const rolePermissions = (data) => ({
  type: types.ROLE_PERMISSIONS,
  data,
});

export const contantAllviewroless = (data) => ({
  type: types.VIEW_ROLES,
  data,
});
export const viewOfAllUsers = (data) => ({
  type: types.VIEW_USERS,
  data,
});
export const viewOfAllProducts = (data) => ({
  type: types.VIEW_PRODUCTS,
  data,
});

export const viewReportProblem = (data) => ({
  type: types.VIEW_REPORT_PROBLEM,
  data,
});

export const getNumberOfSerialsForBatch = (data) => ({
  type: types.GET_SERIAL_FOR_BATCH,
  data,
});
export const getBatchByProducts = (data) => ({
  type: types.GET_BATCH_PRODUCT,
  data,
});

export const viewOfAllActiveProducts = (data) => ({
  type: types.VIEW_ACTIVE_PRODUCTS,
  data,
});
export const updateStatusUser = (data) => ({
  type: types.UPDATE_STATUS_USERS,
  data,
});

export const updateStatusBrand = (data) => ({
  type: types.UPDATE_STATUS_BRAND,
  data,
});

export const edituserChild = (data) => ({
  type: types.EDIT_USER,
  data,
});

export const allbrandgetchild = (data) => ({
  type: types.ALL_BRAND,
  data,
});

export const FetchallFAQs = (data) => ({
  type: types.ALL_FAQS,
  data,
});

export const FetchallTerms = (data) => ({
  type: types.ALL_TERMS,
  data,
});

export const brandgetoneDetails = (data) => ({
  type: types.BRAND_ONE_DETAIL,
  data,
});

export const brandediting = (data) => ({
  type: types.BRAND_EDIT,
  data,
});

export const getallcategeorychild = (data) => ({
  type: types.GETALL_CATEGEORY,
  data,
});

export const getAllActiveCompanies = (data) => ({
  type: types.GET_ALL_ACTIVE_COMPANIES,
  data,
});

export const getAllColorShift = (data) => ({
  type: types.GET_ALL_COLOR_SHIFT,
  data,
});

export const getAllActiveBrands = (data) => ({
  type: types.GET_ALL_ACTIVE_BRANDS,
  data,
});

export const categorystatusupdate = (data) => ({
  type: types.STATUS_UPDATE_CATEGORY,
  data,
});

export const SmsReplystatusupdate = (data) => ({
  type: types.STATUS_UPDATE_SMSREPLY,
  data,
});

export const categoryfetchregister = (data) => ({
  type: types.REGISTER_CATEGORY_STORE,
  data,
});

export const editcatgorychild = (data) => ({
  type: types.EDIT_CATEGORY,
  data,
});

export const editcolorshift = (data) => ({
  type: types.EDIT_COLOR_SHIFT,
  data,
});

export const editsmsReply = (data) => ({
  type: types.EDIT_SMSREPLY,
  data,
});

export const subcategorygetall = (data) => ({
  type: types.ALL_SUB_CATEGORIES,
  data,
});

export const smsReplygetAll = (data) => ({
  type: types.ALL_SMS_REPLY,
  data,
});
export const subcategoryupdatestats = (data) => ({
  type: types.SUB_CATEGORY_SATUS_UPDATE,
  data,
});

export const categorysubediting = (data) => ({
  type: types.EDIT_SUB_CATEGORY,
  data,
});

export const subcategorycreating = (data) => ({
  type: types.CREATE_SUB_CATEGORY,
  data,
});

export const allcompanychild = (data) => ({
  type: types.ALL_COMPANY,
  data,
});

export const Progressive = (data) => ({
  type: types.PROGRESSIVE_CODES,
  data,
});
export const allBatchesActions = (data) => ({
  type: types.ALL_BATCHES,
  data,
});


export const allBatchesgetAllActions = (data) => ({
  type: types.ALL_BATCHES_BOTH_GET,
  data,
});


export const allCustomerGetActions = (data) => ({
  type: types.ALL_GET_CUSTOMER,
  data,
});

export const allCustomerActiveActions = (data) => ({
  type: types.ACTIVE_GET_CUSTOMER,
  data,
});

export const CustomerPostActions = (data) => ({
  type: types.ALL_POST_CUSTOMER,
  data,
});
export const CustomerUpdateActions = (data) => ({
  type: types.UPDATE_POST_CUSTOMER,
  data,
});

export const statusupdatecustomer = (data) => ({
  type: types.UPDATE_STATUS_CUSTOMER,
  data,
});



export const allCustomBatchesActions = (data) => ({
  type: types.ALL_CUSTOM_BATCHES,
  data,
});


export const allBatchesCompany = (data) => ({
  type: types.ALL_BATCHES_COMPANY,
  data,
});

export const createcompanies = (data) => ({
  type: types.CREATE_COMPANY,
  data,
});

export const statusupdatecompany = (data) => ({
  type: types.STATUS_UPDATE_COMPANY,
  data,
});

export const companydetailuser = (data) => ({
  type: types.COMPANY_DETAIL_ONE_USER,
  data,
});

export const allcount = (data) => ({
  type: types.All_COUNTS,
  data,
});

export const viewAllRandomCode = (data) => ({
  type: types.All_RANDOM_CODE,
  data,
});

export const postRandomCode = (data) => ({
  type: types.REQUEST_RANDOM_CODE,
  data,
});

export const viewCustombatch = (data) => ({
  type: types.VIEW_CUSTOM_BATCH,
  data,
});


export const postCustomsbatch = (data) => ({
  type: types.POST_CUSTOM_BATCH,
  data,
});
export const updateCustomsbatch = (data) => ({
  type: types.POST_UPDATE_CUSTOM_BATCH,
  data,
});



export const PostSMSReplies = (data) => ({
  type: types.POST_SMS_REPLY,
  data,
});
export const PostChangePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  data,
});

export const PostUpdateFAQs = (data) => ({
  type: types.POST_UPDATE_FAQS,
  data,
});

export const exportcsvchild = (data) => ({
  type: types.EXPORT_CSV,
  data,
});

export const exportcsvQRchild = (data) => ({
  type: types.EXPORT_QR_CSV,
  data,
});

export const PostUpdateTerms = (data) => ({
  type: types.POST_UPDATE_TERMS,
  data,
});

export const viewAllSMSLog = (data) => ({
  type: types.ALL_SMS_LOGS,
  data,
});

export const getcsv = (data) => ({
  type: types.GET_CSV,
  data,
});

export const viewAllSMSReport = (data) => ({
  type: types.ALL_SMS_REPORT,
  data,
});

export const viewAllQrcodeReport = (data) => ({
  type: types.ALL_QRCODE_REPORT,
  data,
});

export const CompanyCsv = (data) => ({
  type: types.EXPORT_CSV_COMPANY,
  data,
});
export const ReportProblemCsv = (data) => ({
  type: types.EXPORT_CSV_REPORT_PROBLEM,
  data,
});

export const exportCsvcategory = (data) => ({
  type: types.EXPORT_CSV_CATEGORYIES,
  data,
});

export const exportcsvCodeRandoms = (data) => ({
  type: types.EXPORT_CSV_RANDOM_CODE,
  data,
});

export const childdaTapostpreee = (data) => ({
  type: types.PER_POST_ID,
  data,
});

export const mapData = (data) => ({
  type: types.MAP_DATA,
  data,
});

export const PostDataFetchGeting = (data) => (dispatch) => {
  shop.PostDataFetchGeting(data, (e) => {
    dispatch(childdaTapostpreee(e, data));
  });
};

export const FetchMapingData = (data) => (dispatch) => {
  shop.FetchMapingData(data, (e) => {
    dispatch(mapData(e, data));
  });
};

// const history=useHistory()
export const ExportCSVRadmonCOde = (data) => (dispatch) => {
  shop.ExportCSVRadmonCOde(data, (e) => {
    dispatch(exportcsvCodeRandoms(e, data));
  });
};

export const fetchALlDataExportCSVCategoeries = (data) => (dispatch) => {
  // const history=useHistory()
  shop.fetchALlDataExportCSVCategoeries(data, (e) => {
    dispatch(exportCsvcategory(e, data));
  });
};

export const FetchCSVCmpanyAll = (data) => (dispatch) => {
  shop.FetchCSVCmpanyAll(data, (e) => {
    dispatch(CompanyCsv(e, data));
  });
};
export const FetchCSVReportProblem = (data) => (dispatch) => {
  shop.FetchCSVReportProblem(data, (e) => {
    dispatch(ReportProblemCsv(e, data));
  });
};

export const FetchRegisterBrandOwnerr = (data) => (dispatch) => {
  shop.FetchRegisterBrandOwnerr(data, (e) => {
    dispatch(brandoownerRegis(e, data));
  });
};

export const FetchCreateCompany = (data) => (dispatch) => {
  shop.FetchCreateCompany(data, (e) => {
    dispatch(createcompanies(e, data));
  });
};

export const fetchRegisterBrandUserrs = (data) => (dispatch) => {
  shop.fetchRegisterBrandUserrs(data, (e) => {
    dispatch(branduserfetchregister(e, data));
  });
};

export const FetchExportCSV = (data) => (dispatch) => {
  shop.FetchExportCSV(data, (e) => {
    dispatch(exportcsvchild(e, data));
  });
};

export const FetchExportQRCSV = (data) => (dispatch) => {
  shop.FetchExportQRCSV(data, (e) => {
    dispatch(exportcsvQRchild(e, data));
  });
};

export const RegisterProductz = (data) => (dispatch) => {
  shop.RegisterProductz(data, (e) => {
    dispatch(branduserfetchregister(e, data));
  });
};

export const UpdateStatusCode = (data) => (dispatch) => {
  shop.UpdateStatusCode(data, (e) => {
    dispatch(updatestatuscodes(e, data));
  });
};
export const fetchLoginUser = (data) => (dispatch) => {
  shop.fetchLoginUser(data, (e) => {
    dispatch(fssetchsogsinUser(e, data));
  });
};

export const DiubleVerificationLoginn = (data) => (dispatch) => {
  shop.DiubleVerificationLoginn(data, (e) => {
    dispatch(fetchcilDloginVerrr(e, data));
  });
};

export const ForgetPassword = (data) => (dispatch) => {
  shop.ForgetPassword(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};
export const VerifyOTP = (data) => (dispatch) => {
  shop.VerifyOTP(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};

export const CreateFetchRolee = (data) => (dispatch) => {
  shop.CreateFetchRolee(data, (e) => {
    dispatch(specificroless(e, data));
  });
};
export const EditRole = (data) => (dispatch) => {
  shop.EditRole(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const FetchAllUsersView = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllUsersView(data, (e) => {
    dispatch(viewOfAllUsers(e, data));
  });
};
export const FetchAllProductz = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllProductz(data, (e) => {
    dispatch(viewOfAllProducts(e, data));
  });
};

export const GetReportProblem = (data) => (dispatch) => {
  shop.GetReportProblem(data, (e) => {
    dispatch(viewReportProblem(e, data));
  });
};

export const BatchProductSerialNo = (data) => (dispatch) => {
  // const history=useHistory()
  shop.BatchProductSerialNo(data, (e) => {
    dispatch(getNumberOfSerialsForBatch(e, data));
  });
};

export const getBatchByProductID = (data) => (dispatch) => {
  // const history=useHistory()
  shop.getBatchByProductID(data, (e) => {
    dispatch(getBatchByProducts(e, data));
  });
};

export const FetchAllActiveProducts = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllActiveProducts(data, (e) => {
    dispatch(viewOfAllActiveProducts(e, data));
  });
};
export const FetchUsersStatusUpdateView = (data) => (dispatch) => {
  shop.FetchUsersStatusUpdateView(data, (e) => {
    dispatch(updateStatusUser(e, data));
  });
};
export const FetchProductsStatusUpdateView = (data) => (dispatch) => {
  shop.FetchProductsStatusUpdateView(data, (e) => {
    dispatch(updateStatusUser(e, data));
  });
};

export const FetchBrandStausUpdate = (data) => (dispatch) => {
  shop.FetchBrandStausUpdate(data, (e) => {
    dispatch(updateStatusBrand(e, data));
  });
};
export const FetchAllRoleView = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllRoleView(data, (e) => {
    dispatch(contantAllviewroless(e, data));
  });
};

export const AssignPermissionsShop = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AssignPermissionsShop(data, (e) => {
    dispatch(Childassigpermissions(e, data));
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  // const history=useHistory()
  shop.getAllPermissions(data, (e) => {
    dispatch(childALLPermisssions(e, data));
  });
};

export const GetRolePermissions = (data) => (dispatch) => {
  shop.GetRolePermissions(data, (e) => {
    dispatch(rolePermissions(e, data));
  });
};

export const HandleEditUserApi = (data) => (dispatch) => {
  // const history=useHistory()
  shop.HandleEditUserApi(data, (e) => {
    dispatch(edituserChild(e, data));
  });
};
export const HandleEditProductz = (data) => (dispatch) => {
  // const history=useHistory()
  shop.HandleEditProductz(data, (e) => {
    dispatch(edituserChild(e, data));
  });
};

export const FetchAllBrands = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllBrands(data, (e) => {
    dispatch(allbrandgetchild(e, data));
  });
};

export const GetFaqs = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetFaqs(data, (e) => {
    dispatch(FetchallFAQs(e, data));
  });
};

export const GetTerms = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetTerms(data, (e) => {
    dispatch(FetchallTerms(e, data));
  });
};

export const UpdateRateLimter = (data) => (dispatch) => {
  // const history=useHistory()
  shop.updateVerfiyLimit(data, (e) => {
    dispatch(UpdateLimit(e, data));
  });
};

export const FetchBrandOnesDetail = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchBrandOnesDetail(data, (e) => {
    dispatch(brandgetoneDetails(e, data));
  });
};

export const FetchBrandEditt = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchBrandEditt(data, (e) => {
    dispatch(brandediting(e, data));
  });
};

export const FetchAllCategeory = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllCategeory(data, (e) => {
    dispatch(getallcategeorychild(e, data));
  });
};
export const FetchAllActiveCompanies = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllActiveCompanies(data, (e) => {
    dispatch(getAllActiveCompanies(e, data));
  });
};

export const AllColorShift = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllColorShift(data, (e) => {
    dispatch(getAllColorShift(e, data));
  });
};
export const FetchAllActiveBrands = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllActiveBrands(data, (e) => {
    dispatch(getAllActiveBrands(e, data));
  });
};

export const FetchStatusChange = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchStatusChange(data, (e) => {
    dispatch(categorystatusupdate(e, data));
  });
};

export const FetchStatusChangeSms = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchStatusChangeSms(data, (e) => {
    dispatch(SmsReplystatusupdate(e, data));
  });
};
export const SubCategoryUpdateStatus = (data) => (dispatch) => {
  // const history=useHistory()
  shop.SubCategoryUpdateStatus(data, (e) => {
    dispatch(subcategoryupdatestats(e, data));
  });
};

export const FetchRegisterFetchCategory = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchRegisterFetchCategory(data, (e) => {
    dispatch(categoryfetchregister(e, data));
  });
};

export const fetchEditCategories = (data) => (dispatch) => {
  // const history=useHistory()
  shop.fetchEditCategories(data, (e) => {
    dispatch(editcatgorychild(e, data));
  });
};

export const PostUpdateColorShift = (data) => (dispatch) => {
  // const history=useHistory()
  shop.PostUpdateColorShift(data, (e) => {
    dispatch(editcolorshift(e, data));
  });
};

export const fetchEditSMSReply = (data) => (dispatch) => {
  // const history=useHistory()
  shop.fetchEditSMSReply(data, (e) => {
    dispatch(editsmsReply(e, data));
  });
};

export const AllSubCategoryGet = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllSubCategoryGet(data, (e) => {
    dispatch(subcategorygetall(e, data));
  });
};

export const AllSMSReplyGet = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllSMSReplyGet(data, (e) => {
    dispatch(smsReplygetAll(e, data));
  });
};

export const SubCategoryEditing = (data) => (dispatch) => {
  // const history=useHistory()
  shop.SubCategoryEditing(data, (e) => {
    dispatch(categorysubediting(e, data));
  });
};

export const CategorySubCreate = (data) => (dispatch) => {
  // const history=useHistory()
  shop.CategorySubCreate(data, (e) => {
    dispatch(subcategorycreating(e, data));
  });
};
export const CreateSMS = (data) => (dispatch) => {
  // const history=useHistory()
  shop.CreateSMS(data, (e) => {
    dispatch(subcategorycreating(e, data));
  });
};

export const AllCompanies = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllCompanies(data, (e) => {
    dispatch(allcompanychild(e, data));
  });
};

export const GetProgressive = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetProgressive(data, (e) => {
    dispatch(Progressive(e, data));
  });
};

export const AllBatches = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllBatches(data, (e) => {
    dispatch(allBatchesActions(e, data));
  });
};


export const BatchesGetAll = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllBatchesgettAll(data, (e) => {
    dispatch(allBatchesgetAllActions(e, data));
  });
};

export const GetAllCustomer = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllCustmer(data, (e) => {
    dispatch(allCustomerGetActions(e, data));
  });
};

export const AllActiveCustomer = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetActiveCustmer(data, (e) => {
    dispatch(allCustomerActiveActions(e, data));
  });
};

export const CreatePostCustomer = (data) => (dispatch) => {
  // const history=useHistory()
  shop.postCustomer(data, (e) => {
    dispatch(CustomerPostActions(e, data));
  });
};

export const UpdatePostCustomer = (data) => (dispatch) => {
  // const history=useHistory()
  shop.posteditCustomer(data, (e) => {
    dispatch(CustomerUpdateActions(e, data));
  });
};


export const UpdateIdStatus = (data) => (dispatch) => {
  // const history=useHistory()
  shop.StatusUpdateCustomer(data, (e) => {
    dispatch(statusupdatecustomer(e, data));
  });
};

export const AllCustomBatches = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllcustommBatches(data, (e) => {
    dispatch(allCustomBatchesActions(e, data));
  });
};

export const AllBatchesByCompany = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AllBatches(data, (e) => {
    dispatch(allBatchesCompany(e, data));
  });
};


export const AllCounts = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllCounts(data, (e) => {
    dispatch(allcount(e, data));
  });
};

export const AllTransactions = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllCounts(data, (e) => {
    dispatch(allcount(e, data));
  });
};

export const GetAllRandomCode = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllRandom(data, (e) => {
    dispatch(viewAllRandomCode(e, data));
  });
};

export const StatusUpdateCompanies = (data) => (dispatch) => {
  // const history=useHistory()
  shop.StatusUpdateCompanies(data, (e) => {
    dispatch(statusupdatecompany(e, data));
  });
};

export const StatusUpdateBatches = (data) => (dispatch) => {
  // const history=useHistory()
  shop.StatusUpdateBatches(data, (e) => {
    dispatch(statusupdatecompany(e, data));
  });
};
export const HandleEditCompany = (data) => (dispatch) => {
  // const history=useHistory()
  shop.HandleEditCompany(data, (e) => {
    dispatch(statusupdatecompany(e, data));
  });
};

export const FetchCompanyDetails = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchCompanyDetails(data, (e) => {
    dispatch(companydetailuser(e, data));
  });
};
export const postRandomCodes = (data) => (dispatch) => {
  shop.PostRandomCode(data, (e) => {
    dispatch(postRandomCode(e, data));
  });
};

export const PostSMSReply = (data) => (dispatch) => {
  shop.PostSMSReply(data, (e) => {
    dispatch(PostSMSReplies(e, data));
  });
};

export const ChangePassword = (data) => (dispatch) => {
  shop.ChangePassword(data, (e) => {
    dispatch(PostChangePassword(e, data));
  });
};

export const PostFAQSs = (data) => (dispatch) => {
  shop.PostFAQSs(data, (e) => {
    dispatch(PostUpdateFAQs(e, data));
  });
};

export const PostTerms = (data) => (dispatch) => {
  shop.PostTerms(data, (e) => {
    dispatch(PostUpdateTerms(e, data));
  });
};

export const PostBatch = (data) => (dispatch) => {
  shop.PostBatch(data, (e) => {
    dispatch(postRandomCode(e, data));
  });
};

export const PostCustomBatch = (data) => (dispatch) => {
  shop.PostcustommBatch(data, (e) => {
    dispatch(postCustomsbatch(e, data));
  });
};

export const PostUpdateCustomBatch = (data) => (dispatch) => {
  shop.PostcustomUpdateBatch(data, (e) => {
    dispatch(updateCustomsbatch(e, data));
  });
};
export const PostUpdateBatch = (data) => (dispatch) => {
  shop.PostUpdateBatch(data, (e) => {
    dispatch(postRandomCode(e, data));
  });
};

export const ViewCustomBatch = (data) => (dispatch) => {
  shop.viewSingleCustomBatch(data, (e) => {
    dispatch(viewCustombatch(e, data));
  });
};

//Recieved SMS LOGS
export const GetAllSMSLogs = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllSMSLogs(data, (e) => {
    dispatch(viewAllSMSLog(e, data));
  });
};

export const CsvDownload = (data) => (dispatch) => {
  // const history=useHistory()
  shop.CsvDownload(data, (e) => {
    dispatch(getcsv(e, data));
  });
};

//Recieved SMS Report
export const GetAllSMSReport = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllSMSReport(data, (e) => {
    dispatch(viewAllSMSReport(e, data));
  });
};

//Recieved Qrcode Report
export const GetAllQrcodeReport = (data) => (dispatch) => {
  // const history=useHistory()
  shop.GetAllQrcodeReport(data, (e) => {
    dispatch(viewAllQrcodeReport(e, data));
  });
};
